export default {
    mapcoordinate:['33.968744,35.620250'],
    website: {
      //urlapi: 'http://192.168.2.100/recap/public/website/api/json/web/v1/',
      //URLWEB:'http://192.168.2.100/recap/public/',
      urlapi: 'https://www.recapleb.com/api/json/web/v1/',
      URLWEB:'https://www.recapleb.com/',
      token:'CREATRIX_WEB_RECAP_1.0.0',
      CMS_VERSION : "1.0.0",
    }
};