import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./lang/en";
const resources = {
  en: {translation:en},
};

i18n.use(initReactI18next).init({
  resources,
  lng:'en', 
  keySeparator: ".",
  fallbackLng: "en",
  debug : false,
  interpolation: {
    escapeValue: false,
    format: function(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    }
  }
});
export default i18n;