import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import i18n from '../../provider/i18n';
import {entity} from '../../provider/common-provider';
import {isArrayCount,isString, isFunction} from '../../assets/jss/typeofdata';
const DefaultModalConst={show:false,modalshow:{}};
const useAlertPopup = () => {
    const [isdisable,setisdisable] = useState(false);
    const [showError,setshowError] = useState(null);
    const [settingsModal,setsettingsModal] = useState(DefaultModalConst);
    const {show,modalshow:{title,titlecolor,icon,msg,blockbutton},buttons}=settingsModal;

    const SetAlertModalClose =async () => {
        setshowError(null);setisdisable(false);setsettingsModal(DefaultModalConst);
    }

    const SetAlerModalData =async (data) => {
        setsettingsModal(data);
    }
    
    const SetAlerModalDataError =async (data) => {
        setshowError(isString(data)?data:null);
    }

    const SetAlerModalDisable =async (data) => {setisdisable(data);}

    async function ButtonFunction(actionFunc,withclose){
        if (withclose===true){SetAlertModalClose();}
        if (isFunction(actionFunc)){
            actionFunc();
        }
    }

    const AlertPopModal = () =>{
    if (show!==true){return null;}
    return(<Modal animation size="sm" show aria-labelledby="contained-modal-alertpage-vcenter" 
     centered className={'Alert_Popup_Alert'} backdrop={'static'}
     //onExited={()=>{setshowError(null);setisdisable(false);setsettingsModal(null);}}
     >
    {(isString(title) || isString(icon)) &&
    <Modal.Header bsPrefix={'alert_header'} className={'d-flex w-100 justify-content-flex-start align-items-center'}> 
        {isString(icon) && <div className={`${isString(titlecolor)?`text-${titlecolor}`:''} w-auto icon_header`}><i className={icon}/></div>}
        {isString(title) && <div className={`${isString(titlecolor)?`text-${titlecolor}`:''} w-100 text_header`}>{title}</div>}
    </Modal.Header>
    }
    {isString(msg) &&
    <Modal.Body bsPrefix={'alert_body'}>{entity(msg)}</Modal.Body>
    }
    {isArrayCount(buttons) &&
        <Modal.Footer bsPrefix={'alert_footer'}>
            {isdisable===true && <div className={'w-100 text-center loading_info'}><Spinner size={'sm'} animation="border" role="status"><span className="sr-only">Loading...</span></Spinner> <span className={'text'}>{i18n.t("pleasewait")}</span></div>}
            {isdisable===false &&
            <div className={blockbutton===true?'w-100':'d-flex w-100 justify-content-flex-start align-items-center'}>
                {buttons.map((item, key) => {
                const {title:titlebutton,icon:iconbutton,variant,withclose,actionFunc}=item;
                return(
                    <React.Fragment key={`key_alertpopup_${key}`} >
                        <Button block size={'sm'} className={'m-0'} variant={variant?variant:'light'} disabled={isdisable} onClick={()=>ButtonFunction(actionFunc,withclose)}>{iconbutton && iconbutton} {titlebutton}</Button>
                    </React.Fragment>
                )
                })}
            </div>
            }
            {isString(showError) &&
                <div className={'text-break error_msg text-danger text-center'}><small>{showError}</small></div>
            }
        </Modal.Footer>
    }
    </Modal>);
    };


    return {AlertPopModal,SetAlerModalData,SetAlertModalClose,SetAlerModalDisable,SetAlerModalDataError}; 
};
export default useAlertPopup;