import React,{useState, useEffect,useContext} from 'react';
import WebNavigator from './navigator/switch';
import {AppContext} from './reducers/context/myContext';
import LoaderPage from '../components/loader';
import ERRORDB from '../components/general/errorpages/errorDB';
function Setup(){
    const {dispatchApp,RequestDataHttp} = useContext(AppContext);
    const [isloading, setisloading] = useState(true);
    const [haveerror, sethaveerror] = useState(false);
    useEffect(() => { 
        fetchdata();
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function fetchdata(){
        setisloading(true);sethaveerror(false);
        try{
            const dataresult=await RequestDataHttp({},'intro-data');
            await dispatchApp({type:'WEB_UPD_DATA',data:dataresult.data});
            await dispatchApp({type:'WEB_UPD_LATEST_WORKS',data:dataresult.latestworks});
            await dispatchApp({type:'WEB_UPD_TOTAL_WORKS',data:dataresult.totalworks});
            setisloading(false);
        }catch(dataerror){
            sethaveerror(true);setisloading(false);
        }
    }

    return (
        <React.Fragment>
            {isloading===true?
                <LoaderPage/>
            :
                haveerror===true?
                    <ERRORDB/>
                :
                <WebNavigator/>
            }
        </React.Fragment>
    ); 
}
export default Setup;