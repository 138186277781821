export default function reducerApp(state,action){
    switch (action.type) {
        case 'WEB_UPD_DATA':
            return {...state,DBweb:action.data}
        case 'WEB_UPD_LATEST_WORKS':
            return {...state,LatestWorks:action.data}
        case 'WEB_UPD_TOTAL_WORKS':
            return {...state,totalworks:action.data}
        case 'WEB_LOADING_TOOGLE':
            return {...state,Apploading:action.data}
        default:
        return state;
    }
};