import SHA256 from 'crypto-js/sha256';
import config from "../../../../provider/config";
export const initialApp={
    DBweb:[],Apploading:true,totalworks:0,LatestWorks:[],
};

export const Generateheader=async(withlanguage)=>{
    const bearerAuth=await SHA256(`${config.website.token}${config.website.CMS_VERSION}`)?.toString();
    return {
        'content-language':withlanguage,
        'accept-language':["en"],
        'X-CUSTOM-HEADER': config.website.CMS_VERSION,
        'X-CLIENT-SECURITY-TOKEN': bearerAuth,
        'Content-Type':'application/x-www-form-urlencoded;multipart/form-data;'
    }
}
