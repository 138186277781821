import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import i18n from '../../provider/i18n';
function FullPageLoader() {
  return (
    <div className="loader-full-page">
        <div className={'container-loader'}>
            <div className="loader"><Spinner animation="grow" size="lg"  variant="secondary" /></div>
            <div className="datatext">{i18n.t("loadingdata")}</div>
        </div>
    </div>
  );
}
export default FullPageLoader;