import React from 'react';
import Button from 'react-bootstrap/Button'
import {CONTAINER} from '../../../../components/bootstrap/htmltags';
import i18n from '../../../../provider/i18n';
import {entity} from '../../../../provider/common-provider';
function ERRORDB() {
  return (
    <React.Fragment><div className="page-error-cms" >
        <CONTAINER classes="p-0 h-100" >
        <div className="container_card">
          <div className={'icon'}><i className="icofont-network"></i></div>

          <div className={'text unselectable'}>{entity(i18n.t("errors.errorDB"))}</div>
          <div className={'button_home'}>
            <Button variant="danger" size={'sm'} type="submit" onClick={()=>{window.top.location.reload();}}>
                <i className="icofont-refresh"></i>{i18n.t("button.reload")}
            </Button>
          </div>
	    </div>
        <div className="text-center mt-1 copyright unselectable">{entity(i18n.t("copyright.developedby"))}</div>
        </CONTAINER>      
        </div></React.Fragment>
  );
}
export default ERRORDB;
