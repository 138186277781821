import React from 'react';
import Container from 'react-bootstrap/Container';
function CONTAINERFULL({classes,children}) {
    return (
    <Container bsPrefix={'container-fluid'} className={classes?classes:''} as={'div'}>
        {children}
    </Container>);
}

function CONTAINER({classes,children}) {
    return (
    <Container bsPrefix={'container'} className={classes?classes:''} as={'div'}>
        {children}
    </Container>);
}
export{
    CONTAINER,CONTAINERFULL
}

