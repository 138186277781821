import React, { Suspense } from 'react';
import {Routes,BrowserRouter,Route } from "react-router-dom";
import FullPageLoader from '../../components/loader/fullpageloader';
const ERROR404 = React.lazy(() => import('../../components/general/errorpages/error404'));
const MainLandPage = React.lazy(() => import('../../screens/main/mainland'));
const MainGroupPage = React.lazy(() => import('../../screens/main/maingroup'));
const MainArtPage = React.lazy(() => import('../../screens/main/mainart'));
const HomePage = React.lazy(() => import('../../screens/home/home'));
//const  history = createBrowserHistory();
//history.listen(e => {window.scrollTo(0, 0)});
function WebNavigator() {
    return (
    <Suspense fallback={<FullPageLoader/>}>
    <BrowserRouter basename="/">
        <Routes>
            <Route exact path="/" element={<HomePage/>} />
            <Route exact path="/en/:thslandurl" element={<MainLandPage/>} />
            <Route exact path="/en/:thslandurl/:thsgroupurl" element={<MainGroupPage/>} />
            <Route exact path="/en/:thslandurl/:thsgroupurl/:thsarturl/" element={<MainArtPage/>} />
            <Route path="*" element={<ERROR404/>}/>
        </Routes>
    </BrowserRouter>
    </Suspense>
    );
} 
export default WebNavigator;
/*
<Route exact path="/" element={<HomePage/>} />
<Route exact path="/:lang(en)/:thslandurl" element={<MainLandPage/>} />
<Route exact path="/:lang(en)/:thslandurl/:thsgroupurl" element={<MainGroupPage/>} />
<Route exact path="/:lang(en)/:thslandurl/:thsgroupurl/:thsarturl/" element={<MainArtPage/>} />
<Route component={ERROR404}/>
*/