export default {
    appname:"recap lebanon",
    pleasewait:"please wait...",
    loading:"loading",
    loadingdata:"loading data...",
    fetchingdata:"fetching data",
    emptydata:"empty data",
    button:{
        goback:"go back",
        gohome:"go home",
        home:"go home",
        back:"back",
        reload:"reload",
        readmore:"read more",
        viewdetails:"view details",
        pdf:"pdf",
        loadmore:"load more",
        sendmesssage:"send messsage",
        seeall:"see all",
    },
    form:{
        firstname:"first name",
        lastname:"last name",
        email:"email",
        mobile:"mobile",
        message:"message",
    },
    form_error:{
        required:"required",
        invalidemail:"invalid email address",
        notmatch:"only {{var}} is allowed",
        min:"minimum {{var}} characters",
        max:"maximum {{var }} characters",
        onlyinteger:"only numbers is allowed",
    },
    extra:{
        errorGetMsg:"An error occured with fetching data!",
        URLnotFound:"The requested URL <span class='url_link'>{{link}}</span>, does not found on this server.<br>That’s all we know",
        errorApi:"Failed to fetch data from server. It could be an internet issue if not you are kindly asked to contact your support by calling us <a rel=\"noopener noreferrer\" href=\"tel:+9613170368\">+961 3 170 368</a> or sending us an email to <a rel=\"noopener noreferrer\" href=\"mailto:+support@creatrix-lb.com\">support@creatrix-lb.com</a>. Thank you!",
    },
    errors:{
        notdefined:"Error with getting data information",
        URLnotFound:"The requested URL <span class='url_link'>{{link}}</span>, does not found on this server.<br>That’s all we know",
        errorDB:"Could not connect to webservice API. Please try again!",
    },
    copyright:{
        developedby:"Powered by <a href=\"https://creatrix-lb.com\" rel=\"noopener noreferrer\" target=\"_blank\">CREATRIX DIGITAL AGENCY</a>",
        copyright:"&copy; Copyright {{date}} {{name}}. All rights reserved.",
        quicklinks:"quick links"
    },
};