import React from 'react';
import parse from 'html-react-parser';
export const entity=(value)=>{
    return parse(`${value}`);
}
export const entityHTML=(value)=>{
    return React.createElement("div", { dangerouslySetInnerHTML: { __html: parse(`${value}`) } })
}

export const mb_to_byte=(size)=>{
    return Number(size)*1024*1024;
}
export const byte_to_mb=(size)=>{
    return size>0?parseFloat(Number(size)/1024/1024).toFixed(2):0;
}

export const replacePathDoc=(value,settings)=>{
    const valuetext = value?.toString();
    const {root_data,pathurl}=settings;
    return valuetext.replace(root_data, pathurl);
};