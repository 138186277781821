import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/scss/bootstrap/bootstrap.scss';
import Setup from './boot/setup';
import { Provider } from "./boot/reducers/context/myContext";
import reportWebVitals from './reportWebVitals';
const root = ReactDOM.createRoot(document.getElementById('CREATRIX'));
root.render(
    <Provider><Setup /></Provider>
);
/*
root.render(
  <React.StrictMode>
    <Provider><Setup /></Provider>
  </React.StrictMode>
);
*/
reportWebVitals();
