import React, {useReducer,useState,useEffect,createContext} from "react";
import axios from 'axios';
import config from "../../../provider/config";
import i18n from "../../../provider/i18n";
import useAlertPopup from '../../../components/alerterror';
import LoaderPage from '../../../components/loader';
import {initialApp,Generateheader} from "./functions/contextconfig";
import reducerApp from "./functions/reducerApp";
const AppContext = createContext({});
const AlertPopUpContext = createContext({});
function Provider({children}) {
    const {AlertPopModal,SetAlerModalData,SetAlertModalClose,SetAlerModalDataError,SetAlerModalDisable}=useAlertPopup();
    const [DBapp, dispatchAppFunc] = useReducer(reducerApp,initialApp);
    const [isloading, setisloading] = useState(true);
    useEffect(() => {
        setisloading(false);
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 

    const RequestDataHttp=(formvalue,pageURL)=>{
        return new Promise(async function (resolve, reject) {
            const myheader=await Generateheader(DBapp.languageWeb);
            let params = new FormData();
            params.append('__FormData',JSON.stringify(formvalue));
            axios({url:`${config.website.urlapi}${pageURL}`,responseType:'json',method:'post',timeout: 8000,data:params,headers:myheader}).then(function (datareturn) {
                const resJson=JSON.parse(JSON.stringify(datareturn.data));
                if (resJson.status===true){resolve(resJson);}else{reject(resJson);}
            }).catch((dataerror)=>{
                reject({status:false,msg:i18n.t("extra.errorGetMsg")}); });
            });
    };

    const dispatchApp=async (value)=>{
        dispatchAppFunc(value);
        return Promise.resolve();
    };

    const dispatchAlertPopup=async (value)=>{
        if (value.type==='OPEN_ALERTPOPUP'){ SetAlerModalData(value.data);}
        else if (value.type==='CLOSE_ALERTPOPUP'){SetAlertModalClose();}
        else if (value.type==='ERROR_ALERTPOPUP'){SetAlerModalDataError(value.data);}
        else if (value.type==='DISABLE_ALERTPOPUP'){SetAlerModalDisable(value.data);}
        else{SetAlertModalClose();}
        return Promise.resolve();
    };

    if (isloading===true){return <LoaderPage/>}
    return (
        <AppContext.Provider value={{DBapp, dispatchApp,RequestDataHttp}}>
            <AlertPopUpContext.Provider value={{dispatchAlertPopup}}>
                {children}
                <AlertPopModal/>
            </AlertPopUpContext.Provider>
        </AppContext.Provider>
    );
}
export { AppContext,AlertPopUpContext,Provider};