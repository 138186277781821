import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import i18n from '../../provider/i18n';
function LoaderPage() {
  return (
    <div className="loader-page-container">
        <div className="loader"><Spinner animation="grow" size="lg"  variant="secondary" /></div>
        <div className="datatext">{i18n.t("loadingdata")}</div>
    </div>
  );
}
export default LoaderPage;